import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useRef, useState } from 'react'
import Slider from 'react-slick/lib/slider';
import { Gallery, Item } from 'react-photoswipe-gallery'

import 'photoswipe/dist/photoswipe.css'

const ImageGallery = ({ title, description, images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <section className="my-20 px-4 lg:px-20">
      {(title || description) && (
        <header className="text-center mb-20">
          {title && (<h2 className='text-3xl md:text-5xl text-hymcBlue'>{title}</h2>)}
          {description && (<div className='mt-4' dangerouslySetInnerHTML={{ __html: description }} />)}
        </header>
      )}
      {images && images.length > 0 && (
        <>
          <ul className="hidden md:grid grid-col-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 items-start gap-4 lg:gap-8">
            <Gallery>
              {images.map((image) => {
                return (
                  <Item
                    key={image.localFile.childImageSharp.id}
                    original={image.localFile.childImageSharp.original.src}
                    width={image.localFile.childImageSharp.original.width}
                    height={image.localFile.childImageSharp.original.height}
                  >
                    {({ ref, open }) => (
                      <li
                        ref={ref}
                        onClick={open}
                        className='relative group'
                      >
                        <GatsbyImage
                          image={getImage(image.localFile)}
                          alt={""}
                          className='aspect-[3/2]'
                        />
                        <div className='absolute inset-0 pointer-events-none flex opacity-0 group-hover:opacity-100 transition-opacity'>
                          <div className='m-auto p-4 bg-white text-hymcBlue rounded-full'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                          </div>
                        </div>
                      </li>
                    )}
                  </Item>
                );
              })}
            </Gallery>
          </ul>
          <div className='md:hidden'>
            <Gallery>
              <Slider
                ref={(slider) => { sliderRef = slider; }}
                beforeChange={(currentSlide, nextSlide) => {
                  setCurrentIndex(nextSlide);
                }}
                {...settings}
              >
                {images.map((image) => {
                  return (
                    <Item
                      key={image.localFile.childImageSharp.id}
                      original={image.localFile.childImageSharp.original.src}
                      width={image.localFile.childImageSharp.original.width}
                      height={image.localFile.childImageSharp.original.height}
                    >
                      {({ ref, open }) => (
                        <div
                          ref={ref}
                          onClick={open}
                          className='relative group'
                        >
                          <GatsbyImage
                            image={getImage(image.localFile)}
                            alt={""}
                            className='aspect-[3/2]'
                          />
                          <div className='absolute inset-0 pointer-events-none flex opacity-0 group-hover:opacity-100 transition-opacity'>
                            <div className='m-auto p-4 bg-white text-hymcBlue rounded-full'>
                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>
                            </div>
                          </div>
                        </div>
                      )}
                    </Item>
                  );
                })}
              </Slider>
            </Gallery>
            <div className="flex justify-between">
              <button type="button" className="group p-4" onClick={previous} disabled={currentIndex === 0}>
                <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg" className='group-[:disabled]:opacity-30 group-[:disabled]:grayscale'>
                  <path d="M34 16.6923L4.58616 16.6923L17.1921 29.2115L15.6073 31L0 15.5L15.6073 0L17.1921 1.78846L4.58616 14.3077L34 14.3077V16.6923Z" fill="#2364B0" />
                </svg>
              </button>
              <button type="button" className="group p-4" onClick={next} disabled={currentIndex === images.length - 1}>
                <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg" className='group-[:disabled]:opacity-30 group-[:disabled]:grayscale'>
                  <path d="M0 14.3077H29.4138L16.8079 1.78846L18.3927 0L34 15.5L18.3927 31L16.8079 29.2115L29.4138 16.6923H0V14.3077Z" fill="#2364B0" />
                </svg>
              </button>
            </div>
          </div>
        </>
      )}
    </section>
  )
}

export default ImageGallery
