import React from 'react'

function AbQuoteSection({text1,quote,text2}) {
  return (
    <div className='quote-section section min-h-[75vh] h-fit flex flex-col justify-center items-center py-[calc(70px+24px)] lg:py-[calc(140px+24px)] xl:pt-[380px] xl:pb-[140px + 50px]'>
        <div className="quote-section-contents min-h-fit flex flex-col justify-between items-center">
            <div className="w-[90%] lg:min-w-[450px] lg:w-[50%]">
            <h5 className='body-text whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: text1}}/>
            </div>
            <div className="w-[90%] border-l-[10px] py-6 border-hymcBlue px-8 max-lg:my-[100px] my-[60px] lg:w-[50%]">
            <h5 className='blog-quote whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: quote}}/>
            </div>
            <div className="w-[90%] lg:w-[50%]">
                <h5 className='body-text whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: text2}}/>
            </div>
        </div>
    </div>
  )
}

export default AbQuoteSection