import React from 'react'
import BigBoxWithText from '../general/BigBoxWithText'

function HpVideoBackground({bgVideoUrl,videoPosterUrl, boxTitle, boxDescription}) {
  return (
    <div className="video-background-section section relative flex h-fit min-h-screen items-center justify-center py-10 py-[70px] lg:py-[140px]">
      <video
        className="videoTag absolute top-0 h-full max-h-full w-full object-cover"
        autoPlay
        playsInline
        loop
        muted
        poster={videoPosterUrl}
      >
        <source src={bgVideoUrl} type="video/mp4" />
      </video>
      <BigBoxWithText boxTitle={boxTitle} boxDescription={boxDescription} />
    </div>
  );
}

export default HpVideoBackground