import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'


function MemberPopup({image,name,description,imageMobile,onCardClick}) {

    const clickChild = (event) => {
        event.stopPropagation()
    }

    return (
        <div onClick={() => onCardClick()} className='popup-section fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,.8)] z-[1000] flex justify-center items-center cursor-pointer'>
            <div onClick={(event) => clickChild(event)} className='popup-inner relative w-[90%] md:min-w-[700px] xl:min-w-[550px] lg:w-[75%] xl:w-[65%] 2xl:w-[55%] h-[75%] max-lg:h-[90%] bg-white sm:p-4 md:p-14 lg:max-h-[570px] cursor-default'>
                <div onClick={() => onCardClick()} className="absolute top-[15px] right-[25px] x-toggle font-lexendMega font-[20px] z-[999] cursor-pointer">
                    X
                </div>
                <div className="popup-contents grid w-full h-full max-w-full max-h-full overflow-hidden">
                    <div className="member-image bg-no-repeat bg-auto bg-center lg:bg-cover h-[300px] w-[100%] relative max-sm:h-[30%]">  
                        <GatsbyImage
                            image={getImage(image.localFile)}
                            alt={"Captain Image"}
                            className={`bg-image ${imageMobile && "max-lg:hidden"}`}
                            />
                        {imageMobile 
                        && <GatsbyImage
                            image={getImage(imageMobile.localFile)}
                            alt={"test alt"}
                            className="bg-image lg:hidden"
                            />
                        }
                        </div>
                        <div className="title-container px-8 lg:mb-16">
                            <h2 className='text-hymcBlue mb-2 text-[clamp(25px,3vw,32px)]'>{name}</h2>
                        </div>
                        <div className="member-info px-8 overflow-auto" >
                            <span className="description-container tracking-wider leading-loose text-[14px] whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: description}}/>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default MemberPopup