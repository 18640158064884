import React from 'react'
import Form from './Form'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'

function ContactHeroSection({background, title, contactText}) {
  return (
    <div className="bottom-clip relative flex h-fit items-center justify-center bg-cover bg-center py-[calc(70px+100px)] lg:py-[calc(140px+100px)]">
      <GatsbyImage
        image={getImage(background.localFile)}
        alt={"test alt"}
        className="bg-image"
      />
      <div className="contant-container z-10 flex h-[65%] w-[95%] flex-col md:w-[80%] lg:w-[50%] lg:min-w-[900px] lg:max-w-[1000px]">
        <h3 className="mb-4 text-left text-white">{title}</h3>
        <div className="form-container grid h-full w-full grid-rows-[auto_auto] lg:grid-cols-[40%_60%]">
          <div className="number-container flex h-full items-center justify-start bg-hymcBlue text-white lg:block lg:pt-12 lg:pl-10">
            <div
              className="text-container whitespace-pre-wrap max-lg:py-6 max-lg:pl-12 max-lg:pr-4 lg:w-[70%]"
              dangerouslySetInnerHTML={{ __html: contactText }}
            />
          </div>
          <div className="form-container bg-white px-6 pt-16 pb-8 md:px-20">
            <div className="form-inner-container">
              <Form />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactHeroSection