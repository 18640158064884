import React from 'react'

function PartnersSupport({ title, text1 }) {
  return (
    <div className='support-section flex flex-col justify-between items-center  lg:p-10'>
      <div className="support-section-inner h-full w-[90%] xl:w-[60%] flex flex-col justify-between items-center">
        <h2 className="title w-[80%] xl:w-[60%] text-hymcBlue text-center mb-20 mt-20 md:mt-0">
          {title}
        </h2>
        <span className='body-text mb-20 w-[90%] xl:w-[70%] text-center whitespace-pre-wrap' dangerouslySetInnerHTML={{ __html: text1 }} />
      </div>
    </div>
  )
}

export default PartnersSupport
