import React, {useState} from 'react'


function Form() {
  const [consentStatus,setConsentStatus] = useState(false)
  const [formName, setFormName] = useState("")
  const [formPhone, setFormPhone] = useState("")
  const [formEmail, setFormEmail] = useState("")
  const [formMessage, setFormMessage] = useState("")
  const [submitMessage, setSubmitMessage] = useState("")
  


  async function postData(url = '', data = {}) {
      await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer', 
        body: JSON.stringify(data) 
      }).then((response) => {
        return response.json(); 
      }
      ).then((response) => {
        setSubmitMessage("success")
      }).catch((e) => {
        setSubmitMessage("error")
      }).finally(() => {
      });
  }

  const submitHandler = (event) => {
    event.preventDefault();
    postData("https://hymc.thinkplus.dev/api/contact-forms", {
      data: {
        Name: formName,
        Email: formEmail,
        Message: formMessage,
        MobileNumber: formPhone,
      },
    })
    setFormName("");
    setFormEmail("");
    setFormPhone("");
    setFormMessage("");
  }
  
  return (
    <form onSubmit={submitHandler} className='flex flex-col'>
        <label htmlFor="name_input" className='text-hymcBlue'>Full Name*</label>
        <input required id="name_input" value={formName} onChange={(event) => setFormName(event.target.value)} name="name" type="text" className='border-b border-b-hymcBlue text-hymcBlue mb-4'/>

        <label htmlFor="phone_input" className='text-hymcBlue'>Phone Number*</label>
        <input required id="phone_input" value={formPhone} onChange={(event) => setFormPhone(event.target.value)} name="phone" type="tel" className='border-b border-b-hymcBlue text-hymcBlue mb-4'/>

        <label htmlFor="email_input" className='text-hymcBlue'>Email*</label>
        <input required id="email_input" value={formEmail} onChange={(event) => setFormEmail(event.target.value)} name="email" type="email" className='border-b border-b-hymcBlue text-hymcBlue mb-4'/>

        <label htmlFor="message_input" className='text-hymcBlue'>Message*</label>
        <textarea required id="message_input" value={formMessage} onChange={(event) => setFormMessage(event.target.value)} name="message" type="text" className='border-b border-b-hymcBlue text-hymcBlue h-24 mb-4 resize-none'/>
        <div className='mb-4'>
            <input required onClick={() => setConsentStatus(!consentStatus)} id="consent_input" type="checkbox" className='border-b border-b-hymcBlue text-hymcBlue mb-4 mr-2 border-hymcBlue'/>
            <label htmlFor="consent_input" className='text-hymcBlue font-[200]'>Accept the <a href="#" className='underline hover:cursor-pointer'>GDPR policy</a></label>

        </div>
        
        <button disabled={!consentStatus} type="submit" className={`border border-hymcBlue text-hymcBlue py-[10px] font-lexendMega text-center w-[40%] max-w-[155px] outline-0 mb-10 ${!consentStatus && "opacity-50"}`}>SEND</button>
        <div className='h-[60px]'>
          {submitMessage === "success" && <span className='success-message text-green-600'>Thank you.<br/> Your message has been sent successfully!</span>}
          {submitMessage === "error" && <span className='error-message text-red-600'>Something went wrong.<br/> Please try again.</span>}
        </div>
    </form>
  )
}

export default Form