import React from 'react'
import PartnersPartnerRow from './PartnersPartnerRow'
import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

function PartnersHonorablePartners() {
  const HonorablePartnersQuery = useStaticQuery(graphql`
  query {
    allStrapiPartner(filter: {HonorablePartner: {eq: false}}) {
      nodes {
        Name
        PartnerWebsite
        Description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        PartnerImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`)
  return (
    <div className='partners-section section clip-only-last no-bottom-gap h-fit flex flex-col justify-center items-center max-lg:pb-[70px] lg:pb-[70px] bg-[#f6f6f6]'>
        <h2 className="title text-hymcBlue w-full border text-center py-32 drop-shadow-lg">
            Honorable Partners
        </h2>
        { HonorablePartnersQuery.allStrapiPartner.nodes.map((partner,index) => (
            <PartnersPartnerRow 
                image={partner.PartnerImage}
                name={partner.Name}
                description={partner.Description.data.childMarkdownRemark.html}
                link={partner.PartnerWebsite}

                curIndex={index}
                key={index}
            />
        ))}
    </div>
  )
}

export default PartnersHonorablePartners