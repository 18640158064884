import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function MemberLogin({ title, description, background, backgroundMobile }) {
  return (
    <div className="bottom-clip relative relative flex h-fit flex-col items-center justify-around gap-y-12 bg-cover bg-left pt-[20vh] pb-[calc(70px+100px)] lg:pb-[calc(140px+100px)]">
      <GatsbyImage
        image={getImage(background.localFile)}
        alt={"Hero Image"}
        className={`bg-image ${backgroundMobile && "max-md:hidden"}`}
      />
      {backgroundMobile && (
        <GatsbyImage
          image={getImage(backgroundMobile.localFile)}
          alt={"Hero Image mobile"}
          className="bg-image md:hidden"
        />
      )}
      <div className="first-text z-10 text-center text-white">
        <h2 className="title font-[100!important]">
          This page is password-protected
        </h2>
        <h4 className="font-[200]">Enter the password to view this page</h4>
      </div>
      <div className="login z-10 flex h-[30vh] w-[85%] flex-col items-center justify-center gap-y-[35px] bg-[rgba(255,255,255,.9)]  xl:w-[50%]">
        <div className="input grid h-[50px] w-[80%] grid-cols-[10%_90%] place-items-center border border-hymcBlue py-2 px-2">
          <div className="bg-red grid h-full w-full place-items-center border-r border-r-hymcBlue">
            <img
              className="h-fit"
              src="https://hymc.thinkplus.dev/uploads/locker_654d138514.svg?updated_at=2022-12-09T08:39:09.247Z"
              alt=""
            />
          </div>
          <input
            type="text"
            placeholder="PASSWORD"
            className="h-full w-full bg-transparent px-5 text-hymcBlue"
          />
        </div>
        <button
          type="button"
          className="h-[40px] w-[150px] border border-hymcBlue font-[200] text-hymcBlue"
        >
          LOGIN
        </button>
      </div>

      <h2 className="title z-10 font-[100!important]  text-white">{title}</h2>

      <h4
        className="z-10 w-[55%] whitespace-pre-wrap text-center font-[200]  text-white"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );
}

export default MemberLogin;
