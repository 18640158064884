import React from 'react'

function BoxWithTextWhite({boxTitle, boxDescription, bgColor="#fff"}) {
  return (
    <div className={`box-with-text z-10 relative w-[85%] w-full sm:min-w-[410px] max-w-[515px] h-full min-h-[540px] pt-14 pb-2 px-12 shadow-[0px_24px_40px_rgba(0,0,0,0.1);]`} 
    style={{backgroundColor:bgColor}}
    >
        <div className='flex flex-col justify-center items-center text-white'>
            <h3 className='text-center mb-7 mb text-hymcBlue'>
                {boxTitle}
            </h3>
            <h5 className='text-left font-extralight mb-7 font-lexendMega text-black whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: boxDescription}}/>
        </div>
    </div>
  )
}

export default BoxWithTextWhite