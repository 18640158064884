import React, { useState, useEffect } from "react";
import ClubMember from "../general/ClubMember";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";

function AbClubMembers({ sectionTitle }) {
  const [clubMemberResults, setClubMemberResults] = useState(10);
  const [remainingMembers, setRemainingMembers] = useState(true);
  const [randomMembersList, setRandomMembersList] = useState([]);

  const ClubMemberQuery = useStaticQuery(graphql`
    query {
      allStrapiMember(filter: { Role: { eq: "Club Member" } }) {
        nodes {
          Name
          Role
          Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          MemberImage {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          MemberImageMobile {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const allClubMembers = ClubMemberQuery.allStrapiMember.nodes.length;
  const clubMemberList = ClubMemberQuery.allStrapiMember.nodes;

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  useEffect(() => {
    const numbersList = [];
    const randomizedMembersList = [];
    for (let i = 0; i < allClubMembers; i++) {
      numbersList.push(i);
    }
    shuffle(numbersList);
    numbersList.map((num) => {
      randomizedMembersList.push(clubMemberList[num]);
    });
    setRandomMembersList(randomizedMembersList);
  }, []);

  useEffect(() => {
    if (allClubMembers < 10) {
      setRemainingMembers(false);
      setClubMemberResults(allClubMembers);
    }
  }, []);

  const setMemberResults = (moreResults) => {
    if (allClubMembers <= clubMemberResults + moreResults) {
      setClubMemberResults(allClubMembers);
      setRemainingMembers(false);
    } else {
      setClubMemberResults(clubMemberResults + moreResults);
    }
  };

  return (
    <div
      className="section club-members-section flex h-fit flex-col items-center justify-between bg-[#f6f6f6] bg-contain bg-center bg-no-repeat py-24"
      style={{
        backgroundImage: `url("https://hymc.thinkplus.dev/uploads/bg_logo_white_7b34e1278c.jpg")`,
      }}
    >
      <h2 className="title mb-12 text-center text-hymcBlue">{sectionTitle}</h2>
      <div className="members-container flex w-full flex-col items-center justify-center">
        <div className="members flex w-full items-center justify-center py-16 px-24">
          <div className="all-club-members-container flex w-fit max-w-[1700px] flex-wrap justify-center gap-y-8 gap-x-8">
            {randomMembersList
              .slice(0, clubMemberResults)
              .map((node, index) => (
                <ClubMember
                  image={node.MemberImage}
                  imageMobile={node.MemberImageMobile}
                  name={node.Name}
                  description={node.Description.data.childMarkdownRemark.html}
                  key={node.Name}
                />
              ))}
          </div>
        </div>
      </div>
      {remainingMembers && (
        <button
          onClick={() => setMemberResults(5)}
          className="border border-hymcBlue py-[10px] px-[100px] font-lexendMega text-hymcBlue"
        >
          MORE
        </button>
      )}
    </div>
  );
}

export default AbClubMembers;
