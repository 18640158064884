import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function PartnersPartnerRow({ image, name, description, link, curIndex, imageBgColor, stack }) {
  return (
    <div className={`flex items-center justify-center flex-col${stack ? "" : " xl:flex-row"}${!stack && curIndex % 2 === 1 ? " xl:flex-row-reverse" : ""}${imageBgColor ? " bg-[#f6f6f6]" : ""}`}>
      <div className={`image-section flex items-center justify-center w-full min-h-[300px] p-20${stack ? "" : " xl:w-[50%] h-full"}`}>
        <GatsbyImage
          image={getImage(image.localFile)}
          alt={name || ""}
          className="max-w-full max-h-full"
        />
      </div>
      <div className={`text-section bg-[#f6f6f6] flex flex-col justify-center items-start p-6 py-12 lg:p-12 xl:p-24 w-full${stack ? "" : " xl:w-[50%]"} h-fit min-h-[50%] xl:h-full`}>
        <div className="flex flex-col justify-between items-start h-fit">
          <h2 className="text-left text-hymcBlue">
            {name}
          </h2>
          <div className='max-h-[492px] md:max-h-screen mt-10 overflow-y-auto md:overflow-visible'>
            <div className="body-text mb-10 whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: description }} />
            <a className='border border-hymcBlue text-hymcBlue py-[10px] px-[60px] font-lexendMega text-center' href={link} target="_blank">VISIT</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PartnersPartnerRow
