import React from 'react'
import BoxWithText from '../general/BoxWithText'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function HpThreeColumns({sectionTitle,boxBackgroundColor,boxTitle1,boxDescription1,boxBackground1,boxBackgroundMobile1,boxTitle2,boxDescription2,boxBackground2,boxBackgroundMobile2,boxTitle3,boxDescription3,boxBackground3,boxBackgroundMobile3}) {
  return (
    <div className="section three-columns relative grid h-fit grid-cols-1 gap-0 bg-hymcBlue xl:min-h-[900px] xl:grid-cols-3">
      <div className="column-1 relative flex h-fit min-h-[700px] flex-col items-center justify-center bg-cover bg-bottom bg-center max-xl:py-[140px] max-lg:py-[70px] max-md:h-screen md:h-[60vh] md:min-h-[700px] xl:h-full xl:min-h-screen ">
        <GatsbyImage
          image={getImage(boxBackground1.localFile)}
          alt={"test alt"}
          className={`bg-image desktop-background flex ${
            boxBackgroundMobile1 && "md:max-xl:hidden"
          }`}
        />
        {boxBackgroundMobile1 && (
          <GatsbyImage
            image={getImage(boxBackgroundMobile1.localFile)}
            alt={"Box 1 Image mobile"}
            className="bg-image mobile-background hidden"
          />
        )}
        <div className="z-10 flex h-fit w-fit w-[80%] flex-col items-center justify-center bg-[rgba(1,87,148,0.9)] py-4 lg:w-[55%] xl:hidden">
          <h2 className="section-title title z-10 mb-[50px] w-[90] px-4 text-center font-[400] text-white xl:hidden">
            {sectionTitle}
          </h2>
          <BoxWithText
            boxTitle={boxTitle1}
            boxDescription={boxDescription1}
            bgColor={"transparent"}
          />
        </div>
        <BoxWithText
          boxTitle={boxTitle1}
          boxDescription={boxDescription1}
          bgColor={boxBackgroundColor}
          customClasses={"max-xl:hidden"}
        />
      </div>
      <div className="column-2 relative relative flex h-fit min-h-[700px] flex-col items-center justify-center bg-cover bg-bottom bg-center max-xl:py-[140px] max-lg:py-[70px] max-md:h-screen md:h-[60vh] md:min-h-[700px] xl:h-full xl:min-h-screen">
        <GatsbyImage
          image={getImage(boxBackground2.localFile)}
          alt={"test alt"}
          className={`bg-image desktop-background flex ${
            boxBackgroundMobile2 && "md:max-xl:hidden"
          }`}
        />
        {boxBackgroundMobile2 && (
          <GatsbyImage
            image={getImage(boxBackgroundMobile2.localFile)}
            alt={"Box 2 Image mobile"}
            className="bg-image mobile-background hidden"
          />
        )}
        <h2 className="section-title title absolute top-[10%] left-0 right-0 m-auto w-[90] text-center text-white max-xl:hidden">
          {sectionTitle}
        </h2>
        <BoxWithText
          boxTitle={boxTitle2}
          boxDescription={boxDescription2}
          bgColor={boxBackgroundColor}
        />
      </div>
      <div className="column-3 relative flex h-fit min-h-[700px] flex-col items-center justify-center bg-cover bg-bottom bg-center max-xl:py-[140px] max-lg:py-[70px] max-md:h-screen md:h-[60vh] md:min-h-[640px] xl:h-full xl:min-h-screen">
        <GatsbyImage
          image={getImage(boxBackground3.localFile)}
          alt={"test alt"}
          className={`bg-image desktop-background flex ${
            boxBackgroundMobile3 && "md:max-xl:hidden"
          }`}
        />
        {boxBackgroundMobile3 && (
          <GatsbyImage
            image={getImage(boxBackgroundMobile3.localFile)}
            alt={"Box 3 Image mobile"}
            className="bg-image mobile-background hidden"
          />
        )}
        <BoxWithText
          boxTitle={boxTitle3}
          boxDescription={boxDescription3}
          bgColor={boxBackgroundColor}
        />
      </div>
    </div>
  );
}

export default HpThreeColumns