import React from 'react'
import PartnersPartnerRow from './PartnersPartnerRow'
import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

function PartnersPartners() {
  const PartnersQuery = useStaticQuery(graphql`
    query {
      allStrapiPartner(filter: {HonorablePartner: {eq: false}}) {
        nodes {
          Name
          PartnerWebsite
          Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          PartnerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <div className='partners-section section clip-only-last no-bottom-gap h-fit flex flex-col justify-center items-center pt-32'>
        <h2 className="title text-hymcBlue w-full border text-center py-32 drop-shadow-lg">
            Partners
        </h2>
        { PartnersQuery.allStrapiPartner.nodes.map((partner,index) => (
            <PartnersPartnerRow 
                image={partner.PartnerImage}
                name={partner.Name}
                description={partner.Description.data.childMarkdownRemark.html}
                link={partner.PartnerWebsite}

                curIndex={index}
                key={index}
            />
        ))}
    </div>
  )
}

export default PartnersPartners