import React from 'react'
import BoxWithTextWhite from '../general/BoxWithTextWhite'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'

function AbHeroSection({sectionTitle, sectionDescription, sectionBackground,sectionBackgroundColor="#0E0A2F",boxTitle1,boxDescription1,boxTitle2,boxDescription2 ,boxTitle3 ,boxDescription3}) {
  return (
    <div className="about-us-outer-container max-xl:section relative">

        <div className='about-us  hero-section xl:section h-screen xl:h-[150vh] flex flex-col justify-center items-center bg-cover bg-no-repeat bg-top pt-[80vh] xl:pt-[50vh] px-8 relative' 
        style={{ 
            backgroundColor:`${sectionBackgroundColor}`
        }}>
          <GatsbyImage
            image={getImage(sectionBackground.localFile)}
            alt={"test alt"}
            className="bg-image"
          />
            <div className='hero-contents flex flex-col justify-center items-center text-white  w-fit min-w-[327px] mb-32 z-10'>
                <h1 className='text-center mb-7'>
                    {sectionTitle}
                </h1>
                <h5 className='text-center w-[90%] font-extralight mb-7 whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: sectionDescription}}/>
            </div>
        </div>
        <div className="hero-three-columns max-h-max grid grid-cols-1 xl:grid-cols-3 top-[75%] xl:mb-[-10%] gap-y-12 gap-x-12 w-full xl:absolute max-xl:pb-[calc(70px+50px)] max-xl:pt-[30px]">
            <div className="column-1 flex justify-center items-center w-full h-full">
                <BoxWithTextWhite 
                    boxTitle={boxTitle1} 
                    boxDescription={boxDescription1} 
                    bgColor='#fff'
                    />
            </div>
            <div className="column-2 flex justify-center items-center h-full">
                <BoxWithTextWhite 
                    boxTitle={boxTitle2} 
                    boxDescription={boxDescription2} 
                    bgColor='#fff'
                    />
            </div>
            <div className="column-3 flex justify-center items-center h-full">
                <BoxWithTextWhite 
                    boxTitle={boxTitle3} 
                    boxDescription={boxDescription3} 
                    bgColor='#fff'
                    />
            </div>
        </div>
    </div>
  )
}

export default AbHeroSection