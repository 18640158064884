import React from 'react'
import Captain from '../general/Captain'
import Member from '../general/Member'
import BoardMember from '../general/BoardMember'
import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

function AbMembersOfTheBoard({ sectionTitle }) {
  const CaptainAndMemberQuery = useStaticQuery(graphql`
    query {
      allStrapiMember(filter: {Role: {in: ["Captain", "Member", "Member Of The Board"]}}) {
        nodes {
          Name
          Role
          BoardRole
          Description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          MemberImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          MemberImageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className='section board-members-section h-fit py-32 bg-contain bg-center bg-no-repeat' style={{ backgroundImage: `url("https://hymc.thinkplus.dev/uploads/bg_logo_blue_19ef249603.jpg")` }}>
      <h2 className="title text-center text-hymcBlue mb-12">
        {sectionTitle}
      </h2>
      <div className="members-container w-full flex flex-col justify-center items-center m-auto gap-y-[25px]">
        {CaptainAndMemberQuery.allStrapiMember.nodes.map((node, index) => {
          if (node.Role === "Captain") {
            return (
              <Captain
                image={node.MemberImage}
                imageMobile={node.MemberImageMobile}
                name={node.Name}
                boardRole={node.BoardRole}
                description={node.Description.data.childMarkdownRemark.html}
                key={index}
              />
            )
          }
        })}
        <div className="honorable-club-members-container w-full flex flex-col justify-center items-center">
          <div className="honorable-club-members w-full justify-center items-center flex py-16 lg:px-24">
            <div className="all-honorable-club-members-container flex justify-center w-fit flex-wrap gap-y-8 gap-x-8 max-w-[1700px]">
              {CaptainAndMemberQuery.allStrapiMember.nodes.map((node, index) => {
                if (node.Role === "Member Of The Board") {
                  return (
                    <BoardMember
                      image={node.MemberImage || null}
                      imageMobile={node.MemberImageMobile || null}
                      name={node.Name}
                      boardRole={node.BoardRole}
                      description={node.Description.data.childMarkdownRemark.html}
                      key={index}
                    />
                  )
                }
              }
              )}
            </div>
          </div>
        </div>
        <div className="members max-sm:w-[90%] xl:w-[70%] 2xl:w-[55%] w-full justify-center  max-w-[1700px] items-center flex py-16">
          <div className="all-members-container flex justify-center w-fit flex-wrap gap-y-8 gap-x-8 ">
            {CaptainAndMemberQuery.allStrapiMember.nodes.map((node, index) => {
              if (node.Role === "Member") {
                return (
                  <Member
                    image={node.MemberImage}
                    imageMobile={node.MemberImageMobile}
                    name={node.Name}
                    description={node.Description.data.childMarkdownRemark.html}
                    key={index}
                  />
                )
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AbMembersOfTheBoard