import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function Member({name,description,image,imageMobile}) {
  return (
    <div className='member-container member w-full h-full flex justify-center items-center sm:min-w-[400px] sm:max-w-[49%] max-w-[95%] lg:basis-1/3 sm:max-lg:basis-full grow'>
    <div className="member-inner-container h-full shadow-[0px_24px_40px_rgba(0,0,0,0.1)] w-full flex flex-col p-6">
        <div className="member-image bg-no-repeat bg-contain lg:bg-cover bg-center h-[230px] w-[100%] relative" >
            <GatsbyImage
            image={getImage(image.localFile)}
            alt={"Captain Image"}
            className={`bg-image ${imageMobile && "max-lg:hidden"}`}
            />
            {imageMobile 
            && <GatsbyImage
                image={getImage(imageMobile.localFile)}
                alt={"test alt"}
                className="bg-image lg:hidden"
              />
            }  
        </div>
        <div className="member-info pt-6 pb-2">
            <div className="title-container">
                <small className='text-hymcBlue'>Member</small>
                <h2 className='text-hymcBlue mb-2'>{name}</h2>
            </div>
            <span className="description-container body-text leading-loose whitespace-pre-wrap" dangerouslySetInnerHTML={{__html: description}}/>
        </div>
    </div>

    </div>
  )
}

export default Member