import React from 'react'
import Captain from '../general/Captain'
import HonorableMember from '../general/HonorableMember'
import { useStaticQuery } from 'gatsby'
import { graphql } from 'gatsby'

function MbHoborMbHonorableClubMembersableClubMembers({ sectionTitle }) {
  const HonorableMemberQuery = useStaticQuery(graphql`
  query {
    allStrapiMember(filter: {Role: {eq: "Honorable Club Member"}}) {
      nodes {
        Name
        Role
        Description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        MemberImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        MemberImageMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`)
  // console.log("All honorable members: ", HonorableMemberQuery)
  return (
    <div className='h-fit py-24 flex flex-col justify-between items-center'>
      <h2 className="title text-center text-hymcBlue mb-12">
        {sectionTitle}
      </h2>
      <div className="honorable-club-members-container w-full flex flex-col justify-center items-center">
        <div className="honorable-club-members w-full justify-center items-center flex py-16 lg:px-24">
          <div className="all-honorable-club-members-container flex justify-center w-fit flex-wrap gap-y-8 gap-x-8 max-w-[1700px]">
            {HonorableMemberQuery.allStrapiMember.nodes.map((node, index) => (
              <HonorableMember
                image={node.MemberImage || null}
                imageMobile={node.MemberImageMobile || null}
                name={node.Name}
                description={node.Description.data.childMarkdownRemark.html}
                key={index}
              />
            )
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MbHoborMbHonorableClubMembersableClubMembers