import React from 'react'

function BoxWithText({boxBackground, boxTitle, boxDescription}) {
  return (
    <div className="box-with-text relative z-10 h-fit max-h-[80%] min-h-[300px] w-[85%] max-w-[1000px] bg-[#015794CC] py-[63px] md:w-[60%] md:min-w-[730px]">
      <div className="flex flex-col items-center justify-center text-white">
        <h2 className="mx-[15px] mb-7 text-center text-3xl leading-snug md:text-[55px] md:leading-[55px]">
          {boxTitle}
        </h2>
        <h5
          className="font-lm mx-4 mb-7 whitespace-pre-wrap text-center font-lexendMega text-sm font-extralight md:mx-[100px]  md:text-[15px]"
          dangerouslySetInnerHTML={{ __html: boxDescription }}
        />
      </div>
    </div>
  );
}

export default BoxWithText