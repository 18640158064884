import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function Captain({name,description,image,imageMobile,boardRole}) {
  return (
    <div className="captain-container member flex items-center justify-center">
      <div className="captain-inner-container grid h-full max-h-[1000px] w-[90%] max-w-[1000px] bg-white shadow-[0px_24px_40px_rgba(0,0,0,0.1)] max-lg:grid-rows-[minmax(300px,30%)_minmax(auto,70%)] max-lg:pt-4 lg:min-w-[850px] lg:grid-cols-[47%_53%] lg:grid-rows-[1000px]">
        <div className="captain-image max-lg relative h-full w-full bg-contain bg-center bg-no-repeat lg:min-h-[300px] lg:bg-cover">
          <GatsbyImage
            image={getImage(image.localFile)}
            alt={`${name} image`}
            className={`bg-image ${imageMobile && "max-lg:hidden"}`}
          />
          {imageMobile && (
            <GatsbyImage
              image={getImage(imageMobile.localFile)}
              alt={`${name} mobile image`}
              className="bg-image lg:hidden"
            />
          )}
        </div>
        <div className="captain-info grid h-full grid-rows-[min-content_auto] px-8 pb-8 max-lg:pt-8 lg:pt-14">
          <div className="title-container">
            <small className="text-hymcBlue">{boardRole}</small>
            <h2 className="member-name mb-8 text-hymcBlue">{name}</h2>
          </div>
          <div className="description-outer-container overflow-auto">
            <span
              className="description-container body-text whitespace-pre-wrap leading-loose"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Captain