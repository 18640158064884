import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function AbBannerSection({title,description,background}) {
  return (
    <div className='section h-screen flex justify-center items-center bg-cover bg-[#555E6B] bg-blend-multiply px-8 relative'
    >
        <GatsbyImage
          image={getImage(background.localFile)}
          alt={"test alt"}
          className="bg-image mix-blend-multiply"
        />
        <div className="lg:w-[50%] h-fit z-10">
            <h2 className='title text-center mb-16 text-white whitespace-nowrap'>
                {title}
            </h2>
            <h5 className='text-center text-white body-text leading-loose whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: description}}/>
        </div>
    </div>
  )
}

export default AbBannerSection