import { graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import SinglePostImage from './SinglePostImage'
import { useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function BlogSection({ heroBackground }) {
  const [postResults, setPostResults] = useState(7)
  const [remainingPosts, setRemainingPosts] = useState(true)

  const allPostsQuery = useStaticQuery(graphql`
    query {
      allStrapiPost(filter: {isNews: {ne: true}}) {
        nodes {
          Title
          id
          isFeatured
          FeaturedImage {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const allPosts = allPostsQuery.allStrapiPost.nodes.length

  useEffect(() => {
    if (allPosts <= 7) {
      setRemainingPosts(false)
      setPostResults(allPosts)
    }
  }, [])

  const setMorePostResults = (moreResults) => {
    if (allPosts <= postResults + moreResults) {
      setPostResults(allPosts)
      setRemainingPosts(false)
    } else {
      setPostResults(postResults + moreResults)
    }

  }


  return (
    <div className="blog-section bottom-clip h-fit">
      <div className="blog-hero-image relative h-[50vh] bg-cover bg-center">
        <GatsbyImage
          image={getImage(heroBackground.localFile)}
          alt={"test alt"}
          className="bg-image mix-blend-multiply"
        />
      </div>
      <div className="blog-contents flex flex-col items-start justify-start bg-hymcBlue pb-[calc(140px+150px)]">
        <div className="posts-container flex flex-wrap items-start justify-end">
          {allPostsQuery.allStrapiPost.nodes
            .slice(0, postResults)
            .map((node, index) => {
              let finalTitle = node.Title.toLowerCase()
                .replace(/[^a-zA-Z0-9\s]/g, "")
                .replace(/\s/g, "-");
              return (
                <SinglePostImage
                  postUrl={finalTitle}
                  background={node.FeaturedImage}
                  title={node.Title}
                  isFeatured={node.isFeatured}
                  columns={"4"}
                  key={index}
                />
              );
            })}
        </div>
        {remainingPosts && (
          <button
            onClick={() => setMorePostResults(6)}
            className="mt-12 border border-white py-2 px-4 font-lexendMega font-[100] text-white"
          >
            LOAD MORE
          </button>
        )}
      </div>
    </div>
  );
}

export default BlogSection
