import React from 'react'

function BoxWithText({ boxTitle, boxDescription, bgColor, customClasses }) {
  return (
    <div
      className={`box-with-text relative z-10 h-fit min-h-[300px] w-[80%] max-w-full px-8 pt-12 lg:w-[55%] xl:min-w-[350px] ${customClasses}`}
      style={{ backgroundColor: bgColor }}
    >
      <div className="flex flex-col items-center justify-center text-white">
        <h3 className="mb-7 text-center">{boxTitle}</h3>
        <h5
          className="font-lm mb-7 whitespace-pre-wrap text-center font-lexendMega font-extralight"
          dangerouslySetInnerHTML={{ __html: boxDescription }}
        />
      </div>
    </div>
  );
}

export default BoxWithText