import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'

function SinglePostImage({postUrl,background,title,isFeatured}) {
  return (
    <Link
      to={`/blog/${postUrl}`}
      className={`relative flex h-[50vh] min-w-full basis-full items-end justify-center bg-cover bg-center md:basis-1/2 ${
        isFeatured && "md:basis-full"
      } xl:basis-1/3 ${isFeatured && "xl:basis-2/3"}`}
    >
      <GatsbyImage
        image={getImage(background.localFile)}
        alt={"test alt"}
        className="bg-image"
      />
      <div
        className="overlay"
        style={{
          backgroundImage: `linear-gradient(0deg, #015794 0%, rgba(1, 87, 148, 0) 61.45%)`,
        }}
      ></div>
      <h4 className=" z-10 px-8 py-4 font-[300] text-white">{title}</h4>
    </Link>
  );
}

export default SinglePostImage