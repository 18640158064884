import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'

const PartnersPartnerSlide = ({ partner }) => {
  return (
    <div className="pr-4 pb-8 pt-10">
      <div className='h-[1048px] max-h-[1048px] flex flex-col px-4 py-16' style={{ boxShadow: "0px 0px 40px 0px #0000001A" }}>
        <div className="flex items-center gap-8">
          <GatsbyImage
            image={getImage(partner.PartnerImage.localFile)}
            alt={partner.Name || ""}
            className="w-28 h-auto flex-shrink-0"
          />
          <p className='text-hymcBlue text-2xl font-light'>{partner.Name}</p>
        </div>
        <div className='flex-1 overflow-auto'>
          <p className="body-text my-10 whitespace-pre-wrap">{partner.Description.data?.childMarkdownRemark.rawMarkdownBody}</p>
          <a className='border border-hymcBlue text-hymcBlue py-[10px] px-[60px] font-lexendMega text-center' href={partner.PartnerWebsite} target="_blank">VISIT</a>
        </div>
      </div>
    </div>
  )
}

export default PartnersPartnerSlide
