import { graphql } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import NewsCard from './NewsCard'

function BlogSection({ heroBackground, heroBackgroundMobile, heroTitle }) {
  const [postResults, setPostResults] = useState(9)
  const [remainingPosts, setRemainingPosts] = useState(true)

  const allPostsQuery = useStaticQuery(graphql`
    query {
      allStrapiPost(
        filter: {isNews: {eq: true}},
        sort: {fields: PublicationDate, order: DESC}
      ) {
        nodes {
          slug
          PublicationDate(formatString: "DD/MM/YYYY")
          Title
          id
          isFeatured
          FeaturedImage {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `)

  const allPosts = allPostsQuery.allStrapiPost.nodes.length

  useEffect(() => {
    if (allPosts <= 9) {
      setRemainingPosts(false)
      setPostResults(allPosts)
    }
  }, [])

  const setMorePostResults = (moreResults) => {
    if (allPosts <= postResults + moreResults) {
      setPostResults(allPosts)
      setRemainingPosts(false)
    } else {
      setPostResults(postResults + moreResults)
    }

  }


  return (
    <div className="blog-section bottom-clip h-fit">
      <div className="blog-hero-image flex relative min-h-[60vh] bg-cover bg-center">
        <div className={heroBackgroundMobile ? "hidden sm:block" : undefined}>
          <GatsbyImage
            image={getImage(heroBackground?.localFile)}
            alt={""}
            className="bg-image mix-blend-multiply"
          />
        </div>
        {heroBackgroundMobile && (
          <div className='sm:hidden'>
            <GatsbyImage
              image={getImage(heroBackgroundMobile.localFile)}
              alt={""}
              className="bg-image mix-blend-multiply"
            />
          </div>
        )}
        {heroTitle && <h1 className='m-auto pt-12 z-10 text-white'>{heroTitle}</h1>}
      </div>
      <div className="blog-contents pt-24 pb-44 px-4">
        <div className="posts-container w-full max-w-[1600px] mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8">
          {allPostsQuery.allStrapiPost.nodes
            .slice(0, postResults)
            .map((node) => {
              return (
                <NewsCard
                  key={node.id}
                  postUrl={node.slug}
                  image={node.FeaturedImage}
                  publicationDate={node.PublicationDate}
                  title={node.Title}
                />
              );
            })}
        </div>
        {remainingPosts && (
          <button
            onClick={() => setMorePostResults(9)}
            className="block mt-32 mx-auto text-sm border border-hymcBlue py-[10px] px-[30px] font-lexendMega text-hymcBlue"
          >
            LOAD MORE
          </button>
        )}
      </div>
    </div>
  );
}

export default BlogSection
