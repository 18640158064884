import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function HpHeroSection({sectionTitle, sectionDescription,sectionBackground,sectionBackgroundMobile}) {
  return (
    <div className="hp-hero-section section relative flex  flex-col items-center justify-center bg-hymcBlue pb-36">
      <GatsbyImage
        image={getImage(sectionBackground.localFile)}
        alt={"Hero Image"}
        className={`hero-bg max-h-max max-w-full ${
          sectionBackgroundMobile && "max-md:hidden"
        }`}
      />
      {sectionBackgroundMobile && (
        <GatsbyImage
          image={getImage(sectionBackgroundMobile.localFile)}
          alt={"Hero Image mobile"}
          className="hero-bg-mobile max-h-max max-w-full md:hidden"
        />
      )}
      <div className="hero-contents z-10 flex w-[30%] min-w-[327px] flex-col items-center justify-end text-white ">
        <h1 className="text-center sm:whitespace-nowrap">
          Hellenic Yacht Masters' Club
        </h1>
        <h4 className="mb-7 text-center text-[clamp(15px,3vmax,35px)] font-[100]">
          {/* to_replace */}
          {sectionTitle}
        </h4>
        <h5 className="mb-7 max-w-[100%] text-center font-extralight">
          {/* to_replace */}
          {sectionDescription}
        </h5>
        <Link className="w-fit self-center text-center" to="/about-us">
          View Details
        </Link>
      </div>
    </div>
  );
}

export default HpHeroSection