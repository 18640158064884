import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import AbBannerSection from "../components/about-us/AbBannerSection";
import AbClubMembers from "../components/about-us/AbClubMembers";
import AbHeroSection from "../components/about-us/AbHeroSection";
import AbHonorableClubMembers from "../components/about-us/AbHonorableClubMembers";
import AbMembersOfTheBoard from "../components/about-us/AbMembersOfTheBoard";
import AbQuoteSection from "../components/about-us/AbQuoteSection";
import BlogSection from "../components/blog/BlogSection";
import NewsSection from "../components/blog/NewsSection";
import ContactHeroSection from "../components/contact/ContactHeroSection";
import HpHeroSection from "../components/homepage/HpHeroSection";
import HpThreeColumns from "../components/homepage/HpThreeColumns";
import HpVideoBackground from "../components/homepage/HpVideoBackground";
import Layout from "../components/Layout";
import MemberLogin from "../components/members/MemberLogin";
import Seo from "../components/meta/Seo";
import PartnersHeroSection from "../components/partners/PartnersHeroSection";
import PartnersHonorablePartners from "../components/partners/PartnersHonorablePartners";
import PartnersPartners from "../components/partners/PartnersPartners";
import PartnersSupport from "../components/partners/PartnersSupport";
import PartnersPlatinumPartners from "../components/partners/PartnersPlatinumPartners";
import PartnersGoldPartners from "../components/partners/PartnersGoldPartners";
import PartnersSilverPartners from "../components/partners/PartnersSilverPartners";
import PartnersBronzePartners from "../components/partners/PartnersBronzePartners";
import SimplePageHeader from "../components/builder/SimplePageHeader";
import ImageGallery from "../components/builder/ImageGallery";

export default function HomepageTemplate({ data }) {
  const allSections = data.strapiPage.Sections;

  return (
    <Layout url={data.strapiPage.Url}>
      <Seo
        page={data.strapiPage}
        curPage={data.strapiPage.Title}
        pageType={"page"}
      />
      <GatsbySeo
        title={data.strapiPage.Meta.PageMetaTitle}
        description={data.strapiPage.Meta.PageMetaDescription}
        titleTemplate="HYMC | %s"
        language="en"
        noindex={data.strapiPage.HideFromSearchEngines}
        nofollow={data.strapiPage.HideFromSearchEngines}
      />
      <section>
        {allSections.map((section, index) => {
          switch (section.strapi_component) {
            case "homepage.hp-hero-section":
              return (
                <HpHeroSection
                  sectionTitle={section.hhsSectionTitle}
                  sectionDescription={section.hhsSectionDescription}
                  sectionBackground={section.hhsSectionBackground}
                  sectionBackgroundMobile={section.hhsSectionBackgroundMobile}
                  key={section.id}
                />
              );
            case "homepage.hp-three-columns":
              return (
                <HpThreeColumns
                  sectionTitle={section.htcSectionTitle}
                  boxBackgroundColor={section.htcBoxBackgroundColor}
                  boxTitle1={section.htcBoxTitle1}
                  boxDescription1={
                    section.htcBoxDescription1.data.childMarkdownRemark.html
                  }
                  boxBackground1={section.htcBoxBackground1}
                  boxBackgroundMobile1={section.htcBoxBackgroundMobile1}
                  boxTitle2={section.htcBoxTitle2}
                  boxDescription2={
                    section.htcBoxDescription2.data.childMarkdownRemark.html
                  }
                  boxBackground2={section.htcBoxBackground2}
                  boxBackgroundMobile2={section.htcBoxBackgroundMobile2}
                  boxTitle3={section.htcBoxTitle3}
                  boxDescription3={
                    section.htcBoxDescription3.data.childMarkdownRemark.html
                  }
                  boxBackground3={section.htcBoxBackground3}
                  boxBackgroundMobile3={section.htcBoxBackgroundMobile3}
                  key={section.id}
                />
              );
            case "homepage.hp-video-background":
              return (
                <HpVideoBackground
                  bgVideoUrl={section.hvbVideo.localFile.url}
                  VideoPosterUrl={section.hvbPosterImg.localFile.url}
                  boxTitle={section.hvbBoxTitle}
                  boxDescription={
                    section.hvbBoxDescription.data.childMarkdownRemark.html
                  }
                  key={section.id}
                />
              );
            case "about-us.ab-hero-section":
              return (
                <AbHeroSection
                  sectionTitle={section.ahsSectionTitle}
                  sectionDescription={
                    section.ahsSectionDescription.data.childMarkdownRemark.html
                  }
                  sectionBackground={section.ahsSectionBackground}
                  sectionBackgroundMobile={section.ahsSectionBackgroundMobile}
                  sectionBackgroundColor={section.ahsSectionBackgroundColor}
                  boxTitle1={section.ahsTitle1}
                  boxDescription1={
                    section.ahsDescription1.data.childMarkdownRemark.html
                  }
                  boxTitle2={section.ahsTitle2}
                  boxDescription2={
                    section.ahsDescription2.data.childMarkdownRemark.html
                  }
                  boxTitle3={section.ahsTitle3}
                  boxDescription3={
                    section.ahsDescription3.data.childMarkdownRemark.html
                  }
                  key={section.id}
                />
              );
            case "about-us.ab-quote-section":
              return (
                <AbQuoteSection
                  text1={section.aqsText1.data.childMarkdownRemark.html}
                  quote={section.aqsQuote.data.childMarkdownRemark.html}
                  text2={section.aqsText2.data.childMarkdownRemark.html}
                  key={section.id}
                />
              );
            case "about-us.ab-banner-section":
              return (
                <AbBannerSection
                  title={section.absTitle}
                  description={
                    section.absDescription.data.childMarkdownRemark.html
                  }
                  background={section.absBackground}
                  key={section.id}
                />
              );
            case "about-us.ab-members-of-the-board":
              return (
                <AbMembersOfTheBoard
                  sectionTitle={section.amtbSectionTitle}
                  key={section.id}
                />
              );
            case "about-us.ab-club-members":
              return (
                <AbClubMembers
                  sectionTitle={section.acmSectionTitle}
                  key={section.id}
                />
              );
            case "about-us.ab-honorable-club-members":
              return (
                <AbHonorableClubMembers
                  sectionTitle={section.ahcmSectionTitle}
                  key={section.id}
                />
              );
            case "blog.blog-section":
              return (
                <BlogSection
                  heroBackground={section.blogHeroBackground}
                  key={section.id}
                />
              );
            case "blog.news-section":
              return (
                <NewsSection
                  heroBackground={section.NewsHeroBackground}
                  heroBackgroundMobile={section.NewsHeroBackgroundMobile}
                  heroTitle={section.NewsHeroTitle}
                  key={section.id}
                />
              );
            case "partner.partners-hero-section":
              return (
                <PartnersHeroSection
                  background={section.phsBackground}
                  backgroundMobile={section.phsBackgroundMobile}
                  key={section.id}
                />
              );
            case "partner.partners-support":
              return (
                <PartnersSupport
                  title={section.psSectionTitle}
                  text1={section.psText1.data.childMarkdownRemark.html}
                  key={section.id}
                />
              );
            case "partner.partners-partners":
              return (
                <PartnersPartners
                  title={section.ppSectionTitle}
                  key={section.id}
                />
              );
            case "partner.partners-honorable-partners":
              return (
                <PartnersHonorablePartners
                  title={section.ahcmSectionTitle}
                  key={section.id}
                />
              );
            case "partner.partners-platinum-partners":
              return (
                <PartnersPlatinumPartners
                  title={section.pppSectionTitle}
                  key={section.id}
                />
              );
            case "partner.partners-gold-partners":
              return (
                <PartnersGoldPartners
                  title={section.pgpSectionTitle}
                  key={section.id}
                />
              );
            case "partner.partners-silver-partners":
              return (
                <PartnersSilverPartners
                  title={section.pspSectionTitle}
                  key={section.id}
                />
              );
            case "partner.partners-bronze-partners":
              return (
                <PartnersBronzePartners
                  title={section.pbpSectionTitle}
                  key={section.id}
                />
              );
            case "contact.contact-hero-section":
              return (
                <ContactHeroSection
                  title={section.contactTitle}
                  contactText={
                    section.contactText.data.childMarkdownRemark.html
                  }
                  background={section.contactBackground}
                  key={section.id}
                />
              );
            case "member.member-login":
              return (
                <MemberLogin
                  title={section.mlTitle}
                  description={
                    section.mlDescription.data.childMarkdownRemark.html
                  }
                  background={section.mlBackgroundImage}
                  backgroundMobile={section.mlBackgroundImageMobile}
                  key={section.id}
                />
              );
            case "builder.simple-page-header":
              return (<SimplePageHeader key={section.id} />)
            case "builder.image-gallery":
              return (
                <ImageGallery
                  key={section.id}
                  title={section.Title}
                  description={section.Description?.data?.childMarkdownRemark?.html}
                  images={section.Images}
                />
              )
            default:
              console.log("The section does not exist");
              return null;
          }
        })}
      </section>
    </Layout>
  );
}

export const query = graphql`
  query HomepageQuery($pageId: String) {
    strapiPage(id: { eq: $pageId }) {
      Title
      Description
      HideFromSearchEngines
      Meta {
        PageMetaDescription
        PageMetaTitle
        PageMetaImage {
          width
          height
          url
          localFile {
            url
          }
          alternativeText
        }
      }
      Url
      Sections {
        ... on STRAPI__COMPONENT_ABOUT_US_AB_BANNER_SECTION {
          absTitle
          absDescription {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          strapi_component
          absBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          id
        }
        ... on STRAPI__COMPONENT_ABOUT_US_AB_CLUB_MEMBERS {
          acmSectionTitle

          strapi_component
          id
        }
        ... on STRAPI__COMPONENT_ABOUT_US_AB_HERO_SECTION {
          strapi_component
          ahsSectionTitle
          ahsSectionBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ahsSectionBackgroundMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ahsSectionDescription {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          ahsTitle1
          ahsDescription1 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          ahsTitle2
          ahsDescription2 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          ahsTitle3
          ahsDescription3 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          id
          ahsBoxBackgroundColor
        }
        ... on STRAPI__COMPONENT_ABOUT_US_AB_HONORABLE_CLUB_MEMBERS {
          strapi_component
          ahcmSectionTitle
          id
        }
        ... on STRAPI__COMPONENT_ABOUT_US_AB_MEMBERS_OF_THE_BOARD {
          strapi_component
          amtbSectionTitle
          id
        }
        ... on STRAPI__COMPONENT_ABOUT_US_AB_QUOTE_SECTION {
          strapi_component
          aqsText1 {
            data {
              aqsText1
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          aqsQuote {
            data {
              aqsQuote
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          aqsText2 {
            data {
              aqsText2
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          id
        }
        ... on STRAPI__COMPONENT_BLOG_BLOG_SECTION {
          strapi_component
          blogHeroBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          id
        }
        ... on STRAPI__COMPONENT_BLOG_NEWS_SECTION {
          strapi_component
          NewsHeroTitle
          NewsHeroBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          NewsHeroBackgroundMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          id
        }
        ... on STRAPI__COMPONENT_HOMEPAGE_HP_HERO_SECTION {
          strapi_component
          hhsSectionTitle
          hhsSectionDescription
          hhsSectionBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          hhsSectionBackgroundMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  breakpoints: [400, 500, 768]
                  placeholder: BLURRED
                )
              }
            }
          }
          hhsSectionBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          id
        }
        ... on STRAPI__COMPONENT_HOMEPAGE_HP_THREE_COLUMNS {
          htcBoxBackgroundColor
          htcSectionTitle
          htcBoxTitle1
          htcBoxDescription1 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          htcBoxBackground1 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          htcBoxBackgroundMobile1 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          htcBoxTitle2
          htcBoxDescription2 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          htcBoxBackground2 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          htcBoxBackgroundMobile2 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          htcBoxTitle3
          htcBoxDescription3 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          htcBoxBackground3 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          htcBoxBackgroundMobile3 {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          strapi_component
          id
        }
        ... on STRAPI__COMPONENT_HOMEPAGE_HP_VIDEO_BACKGROUND {
          hvbBoxTitle
          hvbBoxDescription {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          hvbPosterImg {
            localFile {
              url
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          hvbVideo {
            localFile {
              url
            }
          }
          strapi_component
          id
        }
        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_HERO_SECTION {
          strapi_component
          phsBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          phsBackgroundMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          id
        }
        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_PARTNERS {
          strapi_component
          id
          ppSectionTitle
        }
        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_SUPPORT {
          id
          psText1 {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          psSectionTitle
          strapi_component
        }

        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_HONORABLE_PARTNERS {
          strapi_component
          id
          phpSectionTitle
        }

        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_PLATINUM_PARTNERS {
          strapi_component
          id
          pppSectionTitle
        }

        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_GOLD_PARTNERS {
          strapi_component
          id
          pgpSectionTitle
        }

        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_SILVER_PARTNERS {
          strapi_component
          id
          pspSectionTitle
        }

        ... on STRAPI__COMPONENT_PARTNER_PARTNERS_BRONZE_PARTNERS {
          strapi_component
          id
          pbpSectionTitle
        }

        ... on STRAPI__COMPONENT_CONTACT_CONTACT_HERO_SECTION {
          contactTitle
          contactBackground {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          contactText {
            data {
              childMarkdownRemark {
                rawMarkdownBody
                html
              }
            }
          }
          strapi_component
          id
        }
        ... on STRAPI__COMPONENT_MEMBER_MEMBER_LOGIN {
          mlTitle
          mlDescription {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          mlBackgroundImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          mlBackgroundImageMobile {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          strapi_component
          id
        }
        ... on STRAPI__COMPONENT_BUILDER_IMAGE_GALLERY {
        Description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        Images {
          localFile {
            childImageSharp {
              original {
                height
                src
                width
              }
              gatsbyImageData(placeholder: BLURRED)
              id
            }
          }
        }
        Title
        id
        strapi_component
      }
      ... on STRAPI__COMPONENT_BUILDER_SIMPLE_PAGE_HEADER {
        id
        strapi_component
      }
      }
    }
  }
`;
