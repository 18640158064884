import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MemberPopup from "./MemberPopup";

function ClubMember({ image, name, description, imageMobile }) {
  const [popupOpen, setPopupOpen] = useState(false);

  const onCardClick = () => {
    const theBody = document.querySelector("body");
    theBody.classList.toggle("overflow-hidden");
    const clubMemberSection = document.querySelector(".club-members-section");
    clubMemberSection.classList.toggle("section");
    setPopupOpen(!popupOpen);
  };

  return (
    <div className="club-member-container member flex h-full w-full min-w-[290px] max-w-[290px] grow basis-1/6 cursor-pointer items-center justify-center bg-white">
      <div
        onClick={() => onCardClick()}
        className="club-member-inner-container flex h-full w-full flex-col p-4"
      >
        <div className="member-image relative h-[300px] w-[100%] bg-auto bg-center bg-no-repeat lg:bg-cover">
          <GatsbyImage
            image={getImage(image.localFile)}
            alt={"Captain Image"}
            className={`bg-image ${imageMobile && "max-lg:hidden"}`}
          />
          {imageMobile && (
            <GatsbyImage
              image={getImage(imageMobile.localFile)}
              alt={"test alt"}
              className="bg-image lg:hidden"
            />
          )}
        </div>
        <div className="member-info pt-6 pb-2">
          <div className="title-container">
            <h2 className="mb-2 text-[20px] text-hymcBlue">{name}</h2>
          </div>
          <span
            className="description-container whitespace-pre-wrap text-[14px] leading-loose tracking-wider"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
      {popupOpen && (
        <MemberPopup
          image={image}
          imageMobile={imageMobile}
          name={name}
          description={description}
          onCardClick={onCardClick}
        />
      )}
    </div>
  );
}

export default ClubMember;
