import { Link } from 'gatsby'
import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function NewsCard({ postUrl, image, title, publicationDate }) {
  return (
    <div className="flex flex-col p-6 shadow-[0px_24px_40px_0px_#0000001A] max-w-full">
      <GatsbyImage
        image={getImage(image.localFile)}
        alt={title || ""}
        className="mb-5 aspect-[3/2]"
      />
      {publicationDate && (
        <p className="mb-4">
          {publicationDate}
        </p>
      )}
      <h2 className="flex-1 mb-8 mt-0 text-2xl text-hymcBlue">
        {title}
      </h2>
      <Link
        to={`/news/${postUrl}`}
        className="self-start text-sm border border-hymcBlue py-[10px] px-[30px] font-lexendMega text-hymcBlue"
      >
        READ MORE
      </Link>
    </div>
  );
}

export default NewsCard
