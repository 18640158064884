import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

function PartnersHeroSection({ background, backgroundMobile }) {
  return (
    <div className='parters-hero-section section min-h-[90vh] relative'>
      <div className={backgroundMobile ? "hidden md:block" : ""}>
        <GatsbyImage
          image={getImage(background.localFile)}
          alt=""
          className="bg-image"
        />
      </div>
      {backgroundMobile && (
        <div className='md:hidden'>
          <GatsbyImage
            image={getImage(backgroundMobile.localFile)}
            alt=""
            className="bg-image"
          />
        </div>
      )}
    </div>
  )
}

export default PartnersHeroSection
